<template>
  <div class="baseInfoForm-container">
    <form-panel ref="formPanel" :form="form" :hasHeader="false"  v-bind="submitConfig" :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore" :submitSuccess="submitSuccess">
      <el-form-item label="发票抬头" prop="invoiceHeader">
        <v-input v-model="form.invoiceHeader"></v-input>
      </el-form-item>
      <el-form-item label="企业开户行" prop="enterpriseOpeningBank">
        <v-input v-model="form.enterpriseOpeningBank"></v-input>
      </el-form-item>
      <el-form-item label="企业开户行账号" prop="enterpriseOpeningBankAccount">
        <v-input v-model="form.enterpriseOpeningBankAccount"></v-input>
      </el-form-item>
      <el-form-item label="开户银行" prop="openingBank">
        <v-input v-model="form.openingBank"></v-input>
      </el-form-item>
      <el-form-item label="是否为单一大业主" :rules="[{ required: true, message: '请选择是否为单一大业主', trigger: 'change' }]" prop="singleCompanyOwner">
        <checkbox-plus :value.sync="form.singleCompanyOwner" type="radio" :options="isYesOptions"></checkbox-plus>
      </el-form-item>
      <el-form-item label="邮寄地址" :rules="[{ required: true, message: '请输入邮寄地址', trigger: 'blur' }]"  prop="mailingAddress">
        <v-input v-model="form.mailingAddress"></v-input>
      </el-form-item>
      <el-form-item label="业主工作单位" :rules="[{ required: true, message: '请输入业主工作单位', trigger: 'blur' }]"  prop="ownerWorkCompany">
        <v-input v-model="form.ownerWorkCompany"></v-input>
      </el-form-item>
      <el-form-item label="单位属性" prop="companyType">
        <v-input v-model="form.companyType" :maxlength="20"></v-input>
      </el-form-item>
      <el-form-item label="单位规模" prop="companyPeopleNum">
        <v-input-number v-model="form.companyPeopleNum" :max="99999999"></v-input-number>  人
      </el-form-item>
      <el-form-item label="单位地址" prop="companyAddress">
        <v-input v-model="form.companyAddress"></v-input>
      </el-form-item>
      <el-form-item label="行业" prop="industry">
        <v-input v-model="form.industry" :maxlength="20"></v-input>
      </el-form-item>     
    </form-panel>
  </div>
</template>
<script>
import {
  addJobInfoURL
} from './api'
import {
  isYesOps
} from './map'
import { CheckboxPlus } from 'components/bussiness'
export default {
  components: { CheckboxPlus },
  props: ['jobInfo', 'userId','query'],
  data () {
    return {
      width: 260,
      submitUrl: addJobInfoURL,
      form: {
        workId: '',
        invoiceHeader: '', //发票抬头
        enterpriseOpeningBank: '',   //企业开户行
        enterpriseOpeningBankAccount: '', //企业开户行账号
        openingBank: '', //开户银行
        singleCompanyOwner: undefined, //是否为单一大业主
        mailingAddress: '', //邮寄地址
        ownerWorkCompany: '', //业主工作单位
        companyType: '', //单位属性
        companyPeopleNum: undefined, //单位规模
        companyAddress: '', //单位所在地址
        industry: '', //行业
      },
      submitConfig: {
        submitMethod: 'post'
      },
      isYesOptions: isYesOps
    }
  },
  computed:{
    subPageType() {
      return this.$store.state.theme['sub-page-type'] || 'inner'
    },
    queryReal(){
      return  this.subPageType == 'drawer' ? this.query : this.$route.query
    },
    communityIdParams () {
      return {
        communityId: this.form.communityId
      }
    },
  },
  mounted () {
    console.log(this.jobInfo,'llll')
    this.jobInfo && this.jobInfo.data && this.setBaseInfo()
  },
  methods: {
    setBaseInfo () {
      let keyArr = Object.keys(this.form)
      let newObj = {}
      keyArr.forEach(value => {
        newObj[value] = this.jobInfo.data[value]
      })
      this.form = newObj
      this.form.singleCompanyOwner = this.form.singleCompanyOwner.toString()
      if(this.form.companyPeopleNum == 0){
        this.form.companyPeopleNum = undefined
      }
    },
    submitBefore (data) {
      console.log(data,'data')
      data.communityId = this.queryReal.communityId
      data.userId = this.userId.id
      return true
    },
    submitSuccess (data) {
      if (this.form.equipmentId) {
        this.$message.success('修改成功')
        this.$emit('contact', {
          method: 'setActiveLabel',
          data: '个人信息'
        })     
        return false
      } else {
        this.$message.success('保存成功')
        this.$emit('contact', {
            method: 'setActiveLabel',
            data: '个人信息'
          })
        return false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.baseInfoForm-container {
  height: 100%;
}
::v-deep .el-form-item__label{
  width: 205px !important;
}
::v-deep .el-form-item__content{
  margin-left: 205px !important;
}
</style>
