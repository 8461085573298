var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "houseInfoForm-container" },
    [
      _c(
        "form-panel",
        {
          attrs: {
            hasHeader: false,
            submitUrl: _vm.submitUrl,
            form: _vm.form,
            submitBefore: _vm.submitBefore,
            submitSuccess: _vm.submitSuccess,
          },
          scopedSlots: _vm._u([
            {
              key: "headerSlot",
              fn: function () {
                return [
                  _c("v-button", {
                    attrs: { text: "返回" },
                    on: { click: _vm.returnClick },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.isShowCreate
            ? _c(
                "el-form-item",
                {
                  staticClass: "communityId",
                  attrs: {
                    label: "项目信息",
                    prop: "communityId",
                    rules: [
                      {
                        required: true,
                        message: "请选择项目信息",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "v-select2",
                    _vm._b(
                      {
                        attrs: { placeholder: "查询项目" },
                        model: {
                          value: _vm.form.communityId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "communityId", $$v)
                          },
                          expression: "form.communityId",
                        },
                      },
                      "v-select2",
                      _vm.communityIdConfig,
                      false
                    )
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isShowEdit
            ? _c(
                "el-form-item",
                {
                  staticClass: "communityId",
                  attrs: {
                    label: "项目信息",
                    prop: "communityId",
                    rules: [
                      {
                        required: true,
                        message: "请选择项目信息",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("v-select", {
                    attrs: { options: _vm.communityIdOps },
                    on: { change: _vm.changeCommunityInfo },
                    model: {
                      value: _vm.form.communityId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "communityId", $$v)
                      },
                      expression: "form.communityId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("span", { staticClass: "addHouse", on: { click: _vm.addHouse } }, [
            _vm._v("增加房产"),
          ]),
          _vm.isShowEdit && _vm.isShowOrg
            ? _c(
                "div",
                [
                  _c("span", { staticClass: "orgId" }, [_vm._v("*")]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "组织信息" } },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.orgIdOps },
                        model: {
                          value: _vm.orgId,
                          callback: function ($$v) {
                            _vm.orgId = $$v
                          },
                          expression: "orgId",
                        },
                      }),
                      _c(
                        "v-button",
                        {
                          staticStyle: { "margin-left": "38px" },
                          attrs: { type: "danger" },
                          on: { click: _vm.removeOrg },
                        },
                        [_vm._v("取消关联")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isShowCreate
            ? _c(
                "el-form-item",
                { attrs: { label: "房产信息" } },
                [
                  _c(
                    "v-select2",
                    _vm._b(
                      {
                        attrs: {
                          placeholder: "查询房产",
                          subjoin: _vm.communityIdParams,
                        },
                        on: { onChange: _vm.getHouseType },
                        model: {
                          value: _vm.form.roomId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "roomId", $$v)
                          },
                          expression: "form.roomId",
                        },
                      },
                      "v-select2",
                      _vm.roomIdConfig,
                      false
                    )
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isShowEdit
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "房产信息",
                    prop: "roomId",
                    rules: [
                      {
                        required: true,
                        message: "请选择房产信息",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("v-select", {
                    attrs: { options: _vm.roomIdOps },
                    on: { change: _vm.changeRoomInfo },
                    model: {
                      value: _vm.form.roomId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "roomId", $$v)
                      },
                      expression: "form.roomId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "房产类型", prop: "houseType" } },
            [
              _c("v-select", {
                attrs: { disabled: "", options: _vm.houseTypeOps },
                model: {
                  value: _vm.form.houseType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "houseType", $$v)
                  },
                  expression: "form.houseType",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属组团", prop: "groupTeam" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.groupTeam,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "groupTeam", $$v)
                  },
                  expression: "form.groupTeam",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "房号编号", prop: "roomNumber" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.roomNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "roomNumber", $$v)
                  },
                  expression: "form.roomNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "完整编号", prop: "roomFullNumber" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.roomFullNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "roomFullNumber", $$v)
                  },
                  expression: "form.roomFullNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "租住时间" } },
            [
              _c("v-datepicker", {
                attrs: {
                  startTime: _vm.form.rentStartDate,
                  endTime: _vm.form.rentEndDate,
                },
                on: {
                  "update:startTime": function ($event) {
                    return _vm.$set(_vm.form, "rentStartDate", $event)
                  },
                  "update:start-time": function ($event) {
                    return _vm.$set(_vm.form, "rentStartDate", $event)
                  },
                  "update:endTime": function ($event) {
                    return _vm.$set(_vm.form, "rentEndDate", $event)
                  },
                  "update:end-time": function ($event) {
                    return _vm.$set(_vm.form, "rentEndDate", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "身份",
                prop: "userType",
                rules: [
                  { required: true, message: "请选择身份", trigger: "change" },
                ],
              },
            },
            [
              _c("v-select", {
                attrs: { options: _vm.userTypeOps },
                on: { change: _vm.changeUserType },
                model: {
                  value: _vm.form.userType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "userType", $$v)
                  },
                  expression: "form.userType",
                },
              }),
            ],
            1
          ),
          _vm.isShowHouseHolder
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否户主", prop: "isHouseHolder" } },
                    [
                      _c("checkbox-plus", {
                        staticStyle: { float: "left" },
                        attrs: {
                          type: "radio",
                          options: _vm.isHouseHolderOps,
                          value: _vm.isHouseHolder,
                        },
                        on: {
                          "update:value": function ($event) {
                            _vm.isHouseHolder = $event
                          },
                        },
                      }),
                      _c("span", { staticStyle: { "margin-left": "20px" } }, [
                        _vm._v(
                          "当前户主：  " + _vm._s(_vm.houseHolderName) + "  "
                        ),
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: { color: "red", "margin-left": "20px" },
                        },
                        [_vm._v("注意")]
                      ),
                      _c("span", [_vm._v(":  户主唯一，且身份必须为业主")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.orgTag === 2 || _vm.userTypeOps.length > 3
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "与户主关系", prop: "userRelation" } },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.userRelationOps },
                        model: {
                          value: _vm.form.userRelation,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "userRelation", $$v)
                          },
                          expression: "form.userRelation",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否付款联系人",
                        prop: "isPayment",
                        rules: [
                          {
                            required: true,
                            message: "请选择付款联系人",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.isPaymentOps },
                        model: {
                          value: _vm.form.isPayment,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isPayment", $$v)
                          },
                          expression: "form.isPayment",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否短信联系人",
                        prop: "isMessage",
                        rules: [
                          {
                            required: true,
                            message: "请选择短信联系人",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.isMessageOps },
                        model: {
                          value: _vm.form.isMessage,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isMessage", $$v)
                          },
                          expression: "form.isMessage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "人房关系", prop: "personRoomRelationship" } },
            [
              _c("v-select", {
                attrs: { options: _vm.personRoomList },
                model: {
                  value: _vm.form.personRoomRelationship,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "personRoomRelationship", $$v)
                  },
                  expression: "form.personRoomRelationship",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "租住关系", prop: "lesseeRelationship" } },
            [
              _c("v-select", {
                attrs: { options: _vm.lesseeRelationshipList },
                model: {
                  value: _vm.form.lesseeRelationship,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "lesseeRelationship", $$v)
                  },
                  expression: "form.lesseeRelationship",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "房东姓名", prop: "houseOwnerName" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.houseOwnerName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "houseOwnerName", $$v)
                  },
                  expression: "form.houseOwnerName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "房东身份证号码", prop: "houseOwnerCardnum" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.houseOwnerCardnum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "houseOwnerCardnum", $$v)
                  },
                  expression: "form.houseOwnerCardnum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "房东手机号", prop: "houseOwnerMobilenum" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.houseOwnerMobilenum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "houseOwnerMobilenum", $$v)
                  },
                  expression: "form.houseOwnerMobilenum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "业主状态", prop: "ownerState" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.ownerState,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ownerState", $$v)
                  },
                  expression: "form.ownerState",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "买房日期", prop: "purchaseDate" } },
            [
              _c("v-datepicker", {
                attrs: { type: "date" },
                model: {
                  value: _vm.form.purchaseDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "purchaseDate", $$v)
                  },
                  expression: "form.purchaseDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "退租日期", prop: "rentWithdrawalDate" } },
            [
              _c("v-datepicker", {
                attrs: { type: "date" },
                model: {
                  value: _vm.form.rentWithdrawalDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "rentWithdrawalDate", $$v)
                  },
                  expression: "form.rentWithdrawalDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "人员类型", prop: "personType" } },
            [
              _c("v-select", {
                attrs: { options: _vm.personTypeList },
                model: {
                  value: _vm.form.personType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "personType", $$v)
                  },
                  expression: "form.personType",
                },
              }),
            ],
            1
          ),
          _vm.orgTag === 2 || (_vm.isShowEdit && _vm.userTypeOps.length > 3)
            ? _c(
                "div",
                [
                  _c("el-form-item", { attrs: { label: "是否业委会成员" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.isCommit))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "业委会职位" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.commitDuty))]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.orgTag === 1
            ? _c(
                "div",
                [
                  _c("el-form-item", { attrs: { label: "是否超管" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.isSuperManager))]),
                    _c("span", { staticClass: "isSuperManager" }, [
                      _vm._v(
                        "当前超管：  " + _vm._s(_vm.houseHolderName) + "  "
                      ),
                    ]),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("注意"),
                    ]),
                    _c("span", [_vm._v(":  超管唯一")]),
                  ]),
                  _c("el-form-item", { attrs: { label: "是否为成员管理员" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.isMemberManager))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "是否对外代表" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.isOutsideRepresentative))]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { type: "flex" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "智能开门", prop: "isOpenDoor" } },
                [
                  _c("v-select", {
                    staticClass: "isExamine",
                    attrs: { options: _vm.isOpenDoorOps },
                    model: {
                      value: _vm.form.isOpenDoor,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isOpenDoor", $$v)
                      },
                      expression: "form.isOpenDoor",
                    },
                  }),
                  _c("span", [_vm._v("注：仅控制蓝牙权限")]),
                ],
                1
              ),
              _vm.$store.getters.getPermission("isLadderControl")
                ? _c(
                    "el-form-item",
                    { attrs: { label: "梯控权限", prop: "isOpenDoor" } },
                    [
                      _c("v-checkbox", {
                        attrs: { label: "二维码" },
                        model: {
                          value: _vm.form.isLadderControl,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isLadderControl", $$v)
                          },
                          expression: "form.isLadderControl",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.isShowEdit
            ? _c(
                "el-form-item",
                { attrs: { label: "是否当前住址", prop: "isDefault" } },
                [
                  _c("v-select", {
                    attrs: { disabled: "", options: _vm.isDefaultOps },
                    model: {
                      value: _vm.form.isDefault,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isDefault", $$v)
                      },
                      expression: "form.isDefault",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isAudio,
                  expression: "!isAudio",
                },
              ],
            },
            [
              _vm.isShowEdit
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "审核状态",
                        prop: "isExamine",
                        rules: [
                          {
                            required: true,
                            message: "请选择审核状态",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        staticClass: "isExamine",
                        attrs: { options: _vm.isExamineOps },
                        model: {
                          value: _vm.form.isExamine,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isExamine", $$v)
                          },
                          expression: "form.isExamine",
                        },
                      }),
                      _c("span", [
                        _vm._v("注：已通过用户即为项目住户，未通过则不是"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.isExamine === 2 && _vm.isShowEdit,
                      expression: "form.isExamine === 2 && isShowEdit",
                    },
                  ],
                  attrs: { label: "审核原因", rules: [{ required: true }] },
                },
                [
                  _c("v-textarea", {
                    model: {
                      value: _vm.form.rejectReason,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "rejectReason", $$v)
                      },
                      expression: "form.rejectReason",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.isExamine !== 2 && _vm.isShowEdit,
                      expression: "form.isExamine !== 2 && isShowEdit",
                    },
                  ],
                  attrs: { label: "审核原因", prop: "rejectReason" },
                },
                [
                  _c("v-textarea", {
                    model: {
                      value: _vm.form.rejectReason,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "rejectReason", $$v)
                      },
                      expression: "form.rejectReason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }