<template>
  <div class='OwnerMgrForm-container' :key="vdomId">
    <tabs-panel :tabs="tabs" :tabRouter="false" :before="getDetailData" :activeLabel.sync="activeLabel"></tabs-panel>
  </div>
</template>
<script>
import { TabsPanel } from 'components/bussiness'
import BaseInfoForm from './BaseInfoForm'
import HouseInfoForm from './HouseInfoForm'
import CarInfoForm from './CarInfoForm'
import ParkingLotInfoForm from './ParkingLotInfoForm'
import StoreroomInfoForm from './StoreroomInfoForm'
import LogInfoForm from './LogInfoForm'
import JobInfoForm from './JobInfoForm'
import PersonInfoForm from './PersonInfoForm'
import * as allOwnerMgrFormUrl from './api'
export default {
  name: 'OwnerMgrForm',
  components: {
    TabsPanel
  },
  props:{
    editQuery:Object
  },
  data () {
    return {
      activeName: 'baseInfoForm',
      owner: {
        data: ''
      },
      partyMemberInfo: {
        data: undefined
      },
      ownerAssets: {
        data: ''
      },
      cars: {
        data: ''
      },
      parkingLot: {
        data: ''
      },
      storeroom: {
        data: ''
      },
      ownerWorkInfoV: {
        data: ''
      },
      ownerPersonalInfoV: {
        data: ''
      },
      userId: {
        id: ''
      },
      houseId: {
        id: ''
      },
      tabs: [],
      activeLabel: '基础信息',
      communityIdOps: [],
      vdomId: 1,
      query:{}
    }
  },
  computed: {
    subPageType() {
      return this.$store.state.theme['sub-page-type'] || 'inner'
    },
  },
  created () {
    this.query = this.subPageType == 'drawer' ? this.editQuery : this.$route.query
    let type = Number(this.query.type)
    if(!this.subPageType == 'drawer'){
      if (this.query.id && type === 1) {
        this.$setBreadcrumb('修改注册用户信息')
      } else if (this.query.id && type === 0) {
        this.$setBreadcrumb('修改未注册用户信息')
      } else {
        this.$setBreadcrumb('新增未注册用户信息')
      }
    }
    this.tabs = [
      {
        label: '基础信息',
        component: BaseInfoForm,
        props: {
          query:this.query,
          ownerInfo: this.owner,
          partyMemberInfo: this.partyMemberInfo
        }
      }, {
        label: '房产信息',
        component: HouseInfoForm,
        props: {
          ownerAssets: this.ownerAssets,
          userId: this.userId,
          query:this.query,
          isAudio: this.query.id && type === 1
        }
      }, {
        label: '车辆信息',
        component: CarInfoForm,
        props: {
          cars: this.cars,
          query:this.query,
          userId: this.userId,
          houseId: this.houseId
        }
      }, {
        label: '车位信息',
        component: ParkingLotInfoForm,
        props: {
          query:this.query,
          parkingLot: this.parkingLot,
          userId: this.userId,
          houseId: this.houseId
        }
      },
      {
        label: '库房信息',
        component: StoreroomInfoForm,
        props: {
          query:this.query,
          storeroom: this.storeroom,
          userId: this.userId,
          houseId: this.houseId
        }
      }
    ]

    let logTab = {
      label: '操作日志',
      component: LogInfoForm,
      props: {
        query:this.query,
        storeroom: this.storeroom,
        userId: this.userId,
        houseId: this.houseId
      }
    }

    let jobTab = {
      label: '工作信息',
      component: JobInfoForm,
      props: {
        query:this.query,
        jobInfo: this.ownerWorkInfoV,
        userId: this.userId
      }
    }

    let PersonTab = {
      label: '个人信息',
      component: PersonInfoForm,
      props: {
        query:this.query,
        personInfo: this.ownerPersonalInfoV,
        userId: this.userId
      }
    }

    if(this.query.id && type === 1) {
      this.tabs.push(logTab)
    } 
    
    if(this.$store.getters.getPermission('otherInfo')){
      this.tabs.push(jobTab,PersonTab)
    }
    
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.name === 'ownerCarForm') {
        vm.activeLabel = '车辆信息'
      } else if (from.name === 'carPlaceForm') {
        vm.activeLabel = '车位信息'
      } else if (from.name === 'storeRoomForm') {
        vm.activeLabel = '库房信息'
      } else if (from.name === 'addHouseForm') {
        vm.activeLabel = '房产信息'
      }
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.vdomId++ // 让页面在相同路由情况下重新加载
    next()
  },
  methods: {
    setActiveLabel (activeLabel) {
      this.activeLabel = activeLabel
    },
    getDetailData (next) {
      let type = Number(this.query.type)
      let { addUserId, id, orgId, communityId } = this.query
      if (id || addUserId) {
        let url = type === 1 ? allOwnerMgrFormUrl.getRegisterDetailUrl : allOwnerMgrFormUrl.getUnRegisterDetailUrl
        this.$axios.get(url, {
          params: {
            id: id || addUserId,
            orgId: orgId,
            communityId: communityId
          }
        })
          .then(res => {
            let returnStatus = String(res.status)
            if (returnStatus === '100') {
              this.ownerAssets.data = type === 1 ? res.data.ownerAssets : res.data.communityAssetVS
              this.owner.data = res.data.owner
              this.partyMemberInfo.data = res.data.partyMemberInfo
              this.cars.data = res.data.cars
              this.parkingLot.data = res.data.parkingLot
              this.storeroom.data = res.data.storeroom
              this.ownerPersonalInfoV.data = res.data.ownerPersonalInfoV
              this.ownerWorkInfoV.data = res.data.ownerWorkInfoV
              console.log(this.ownerWorkInfoV,'jjjkkggg')
              this.getCommunityId()
              this.getUserId(res.data.owner.id)
              next()
            }
          })
      } else {
        next()
      }
    },
    getCommunityId () {
      let type = Number(this.query.type)
      let communityAssetVS = type === 1 ? this.ownerAssets.data.communityAssetVS : this.ownerAssets.data
      communityAssetVS.map(item => {
        this.communityIdOps.push({
          text: item.communityName,
          value: item.communityId
        })
      })
      let communityIds = this.communityIdOps.length > 0 ? this.communityIdOps[0].value : ''
      let communityId = this.query.communityId ? this.query.communityId : communityIds
      communityAssetVS.map(item => {
        if (item.communityId === communityId) {
          let houseId = item.assets.length > 0 && item.assets[0].houseId
          this.getHouseId(houseId)
        }
      })
    },
    getUserId (userId) {
      this.userId.id = userId
    },
    getHouseId (houseId) {
      this.houseId.id = houseId
    }
  }
}
</script>
