<template>
  <div class="baseInfoForm-container">
    <form-panel ref="formPanel" :form="form"  v-bind="submitConfig" :hasHeader="false" :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore" >
      <el-form-item label="学历" prop="education" :rules="[{ required: true, message: '请选择学历', trigger: 'change' }]">
        <v-select v-model="form.education" :options="educationOps"></v-select>
      </el-form-item>
      <el-form-item label="征信情况" prop="creditInvestigation">
        <v-input v-model="form.creditInvestigation"></v-input>
      </el-form-item>
      <el-form-item label="子女在读学校" prop="childrenSchool">
        <v-input v-model="form.childrenSchool"></v-input>
      </el-form-item>
      <el-form-item label="血型" prop="bloodType">
        <v-select v-model="form.bloodType" :options="bloodTypeOps"></v-select>
      </el-form-item>
      <el-form-item label="户籍性质" prop="registeredResidence">
        <v-select v-model="form.registeredResidence" :options="registeredResidenceOps"></v-select>
      </el-form-item>
      <el-form-item label="身高" prop="height">
        <v-input-number v-model="form.height" :precision="1"></v-input-number>  CM
      </el-form-item>
      <el-form-item label="体重" prop="weight">
        <v-input-number v-model="form.weight" :precision="2"></v-input-number>  KG
      </el-form-item>
      <el-form-item label="健康状况" prop="health">
        <v-input v-model="form.health"></v-input>
      </el-form-item>
      <el-form-item label="是否为困难户" prop="poorHouseholds">
        <checkbox-plus :value.sync="form.poorHouseholds" type="radio" :options="isYesOptions"></checkbox-plus>
      </el-form-item>
      <el-form-item label="紧急联系人" :rules="[{ required: true, message: '请输入紧急联系人', trigger: 'blur' }]"  prop="emergencyContact">
        <v-input v-model="form.emergencyContact"></v-input>
      </el-form-item>
      <el-form-item label="紧急联系人电话" :rules="mobileNumChangeRules"  prop="emergencyContactPhone">
        <v-input v-model="form.emergencyContactPhone"></v-input>
      </el-form-item>
      <el-form-item label="紧急联系人地址" :rules="[{ required: true, message: '请输入紧急联系人地址', trigger: 'blur' }]"  prop="emergencyContactAddress">
        <v-input v-model="form.emergencyContactAddress"></v-input>
      </el-form-item>
      <el-form-item label="家庭长期饲养的动物种类" :rules="[{ required: true, message: '请输入家庭长期饲养的动物种类', trigger: 'blur' }]"  prop="longTermAnimalSpecies">
        <v-input v-model="form.longTermAnimalSpecies"></v-input>
      </el-form-item>
      <el-form-item label="家庭长期饲养的动物数量" :rules="[{ required: true, message: '请输入家庭长期饲养的动物数量', trigger: 'blur' }]" prop="longTermAnimalNum">
        <v-input-number v-model="form.longTermAnimalNum" :max="99999999"></v-input-number>
      </el-form-item>
    </form-panel>
  </div>
</template>
<script>
import {
  addPersonInfoURL
} from './api'
import * as commonData from './map'
import { CheckboxPlus } from 'components/bussiness'
import { mobileRegular, telephoneRegular } from '@/common/regular'
export default {
  components: { CheckboxPlus },
  props: ['personInfo', 'userId','query'],
  data () {
    const validateMobile = (rule, value, callback) => {
      // let regExp = mobileRegular
      let regExp = /^\d{11}$/
      if (!regExp.test(value)) {
        callback(new Error('手机号码应为11位的数字！'))
      } else {
        callback()
      }
    }
    return {
      width: 260,
      submitUrl: addPersonInfoURL,
      form: {
        personalId: '',
        education: undefined, //学历
        creditInvestigation: '',   //征信情况
        childrenSchool: '', //子女在读学校
        bloodType: undefined, //血型
        registeredResidence: undefined, //户籍性质
        height: undefined, //身高
        weight: undefined, //体重
        health: '', //健康状况
        poorHouseholds: undefined, //是否为困难户
        emergencyContact: '', //紧急联系人
        emergencyContactPhone: '', //紧急联系人电话
        emergencyContactAddress: '', //紧急联系人地址
        longTermAnimalSpecies: '', //家庭长期饲养的动物种类
        longTermAnimalNum: undefined, //家庭长期饲养的动物数量
      },
      submitConfig: {
        submitMethod: 'post'
      },
      userRelationOps: commonData.userRelationOps,
      isYesOptions: commonData.isYesOps,
      educationOps: commonData.educationOps,
      bloodTypeOps: commonData.bloodTypeOps,
      registeredResidenceOps: commonData.registeredResidenceOps,
      mobileNumChangeRules: [
        { required: true, message: '请输入紧急联系人电话', trigger: 'blur' },
        { validator: validateMobile, trigger: 'blur' }
      ],
    }
  },
  computed:{
    subPageType() {
      return this.$store.state.theme['sub-page-type'] || 'inner'
    },
    queryReal(){
      return  this.subPageType == 'drawer' ? this.query : this.$route.query
    },
  },
  mounted () {
    this.personInfo && this.personInfo.data && this.setBaseInfo()
  },
  methods: {
    validatePhone (rule, value, callback) {
      let regExp = mobileRegular
      let connectionRegExp = telephoneRegular
      let showError = false
      if (value && value.length > 0) {
        if (!regExp.test(value) && !connectionRegExp.test(value)) {
          showError = true
        }
      }
      if (showError) {
        callback(new Error('手机/电话号码格式不正确'))
      } else {
        callback()
      }
    },
    setBaseInfo () {
      let keyArr = Object.keys(this.form)
      let newObj = {}
      keyArr.forEach(value => {
        newObj[value] = this.personInfo.data[value]
      })
      this.form = newObj
      this.form.poorHouseholds = this.form.poorHouseholds.toString()
      let keyZero = ['education','bloodType','registeredResidence','height','weight']
      keyZero.forEach(value => {
        if(this.form[value]==0) {
          this.form[value] = undefined
        }
      })
    },
    submitBefore (data) {
      console.log(data,'data')
      data.communityId = this.queryReal.communityId
      data.userId = this.userId.id
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.baseInfoForm-container {
  height: 100%;
}
::v-deep .el-form-item__label{
  width: 205px !important;
}
::v-deep .el-form-item__content{
  margin-left: 205px !important;
}
</style>
