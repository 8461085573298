var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "parkingLotInfoForm-container" },
    [
      _c("form-panel", { attrs: { hasHeader: false } }, [
        _c(
          "ul",
          { staticClass: "parkingLotInfoForm" },
          [
            _vm._l(_vm.parkingLotData, function (datas, index) {
              return _c("li", { key: index }, [
                _c("div", { staticClass: "carInfo" }, [
                  _c("div", [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("用户房号："),
                    ]),
                    _c("span", [_vm._v(_vm._s(datas.houseName))]),
                  ]),
                  _c("div", [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("车位地址："),
                    ]),
                    _c("span", [_vm._v(_vm._s(datas.carHouseName))]),
                  ]),
                ]),
                _c("div", { staticClass: "carInfo" }, [
                  _c("div", [
                    _c("span", { staticClass: "title" }, [_vm._v("车位编号:")]),
                    _c("span", [_vm._v(_vm._s(datas.carNumber))]),
                  ]),
                  _c("div", [
                    _c("span", { staticClass: "title" }, [_vm._v("完整编号:")]),
                    _c("span", [_vm._v(_vm._s(datas.carFullNumber))]),
                  ]),
                ]),
                _c("div", { staticClass: "carInfo" }, [
                  _c("div", [
                    _c("span", { staticClass: "title" }, [_vm._v("车位状态:")]),
                    _c("span", [_vm._v(_vm._s(datas.statusText))]),
                  ]),
                  _c("div", [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("车位证明状态:"),
                    ]),
                    _c("span", [_vm._v(_vm._s(datas.certificateStatusText))]),
                  ]),
                ]),
                _c("div", { staticClass: "carInfo" }, [
                  _c(
                    "div",
                    [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("车位证明:"),
                      ]),
                      _c("v-preview", {
                        attrs: {
                          imgs: datas.annexUrl,
                          textMode: false,
                          buttonText: "查看车位证明照片",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", [
                    _c("span", { staticClass: "title" }, [_vm._v("共享车位:")]),
                    _c("span", [_vm._v(_vm._s(datas.sharedStatusText))]),
                  ]),
                ]),
                _vm.isShowUpdate
                  ? _c(
                      "div",
                      {
                        staticClass: "editPark",
                        on: {
                          click: function ($event) {
                            return _vm.editPark(datas.id)
                          },
                        },
                      },
                      [_vm._v("编辑该车位")]
                    )
                  : _vm._e(),
              ])
            }),
            _vm.isShowAddParkPlace
              ? _c(
                  "div",
                  { staticClass: "createPark", on: { click: _vm.createPark } },
                  [_vm._v("登记车位信息")]
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }