var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: _vm.vdomId, staticClass: "OwnerMgrForm-container" },
    [
      _c("tabs-panel", {
        attrs: {
          tabs: _vm.tabs,
          tabRouter: false,
          before: _vm.getDetailData,
          activeLabel: _vm.activeLabel,
        },
        on: {
          "update:activeLabel": function ($event) {
            _vm.activeLabel = $event
          },
          "update:active-label": function ($event) {
            _vm.activeLabel = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }