var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "baseInfoForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              hasHeader: false,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "学历",
                prop: "education",
                rules: [
                  { required: true, message: "请选择学历", trigger: "change" },
                ],
              },
            },
            [
              _c("v-select", {
                attrs: { options: _vm.educationOps },
                model: {
                  value: _vm.form.education,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "education", $$v)
                  },
                  expression: "form.education",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "征信情况", prop: "creditInvestigation" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.creditInvestigation,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "creditInvestigation", $$v)
                  },
                  expression: "form.creditInvestigation",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "子女在读学校", prop: "childrenSchool" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.childrenSchool,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "childrenSchool", $$v)
                  },
                  expression: "form.childrenSchool",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "血型", prop: "bloodType" } },
            [
              _c("v-select", {
                attrs: { options: _vm.bloodTypeOps },
                model: {
                  value: _vm.form.bloodType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "bloodType", $$v)
                  },
                  expression: "form.bloodType",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "户籍性质", prop: "registeredResidence" } },
            [
              _c("v-select", {
                attrs: { options: _vm.registeredResidenceOps },
                model: {
                  value: _vm.form.registeredResidence,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "registeredResidence", $$v)
                  },
                  expression: "form.registeredResidence",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "身高", prop: "height" } },
            [
              _c("v-input-number", {
                attrs: { precision: 1 },
                model: {
                  value: _vm.form.height,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "height", $$v)
                  },
                  expression: "form.height",
                },
              }),
              _vm._v("  CM\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "体重", prop: "weight" } },
            [
              _c("v-input-number", {
                attrs: { precision: 2 },
                model: {
                  value: _vm.form.weight,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "weight", $$v)
                  },
                  expression: "form.weight",
                },
              }),
              _vm._v("  KG\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "健康状况", prop: "health" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.health,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "health", $$v)
                  },
                  expression: "form.health",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否为困难户", prop: "poorHouseholds" } },
            [
              _c("checkbox-plus", {
                attrs: {
                  value: _vm.form.poorHouseholds,
                  type: "radio",
                  options: _vm.isYesOptions,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.form, "poorHouseholds", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "紧急联系人",
                rules: [
                  {
                    required: true,
                    message: "请输入紧急联系人",
                    trigger: "blur",
                  },
                ],
                prop: "emergencyContact",
              },
            },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.emergencyContact,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "emergencyContact", $$v)
                  },
                  expression: "form.emergencyContact",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "紧急联系人电话",
                rules: _vm.mobileNumChangeRules,
                prop: "emergencyContactPhone",
              },
            },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.emergencyContactPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "emergencyContactPhone", $$v)
                  },
                  expression: "form.emergencyContactPhone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "紧急联系人地址",
                rules: [
                  {
                    required: true,
                    message: "请输入紧急联系人地址",
                    trigger: "blur",
                  },
                ],
                prop: "emergencyContactAddress",
              },
            },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.emergencyContactAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "emergencyContactAddress", $$v)
                  },
                  expression: "form.emergencyContactAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "家庭长期饲养的动物种类",
                rules: [
                  {
                    required: true,
                    message: "请输入家庭长期饲养的动物种类",
                    trigger: "blur",
                  },
                ],
                prop: "longTermAnimalSpecies",
              },
            },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.longTermAnimalSpecies,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "longTermAnimalSpecies", $$v)
                  },
                  expression: "form.longTermAnimalSpecies",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "家庭长期饲养的动物数量",
                rules: [
                  {
                    required: true,
                    message: "请输入家庭长期饲养的动物数量",
                    trigger: "blur",
                  },
                ],
                prop: "longTermAnimalNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: { max: 99999999 },
                model: {
                  value: _vm.form.longTermAnimalNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "longTermAnimalNum", $$v)
                  },
                  expression: "form.longTermAnimalNum",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }