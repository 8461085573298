<template>
  <div class='storeroomInfoForm-container'>
    <form-panel :hasHeader="false">
      <ul class="storeroomInfoForm">
        <li v-for="(storeroomData, index) in storeroom.data" :key="index">
          <div class="carInfo">
            <div>
              <span class="title">用户房号：</span>
              <span>{{storeroomData.userAddress}}</span>
            </div>
            <div>
              <span class="title">库房地址：</span>
              <span>{{storeroomData.communityName}}--{{storeroomData.storeRoomAddress}}</span>
            </div>
          </div>
          <div class="carInfo">
            <div>
              <span class="title">库房编号：</span>
              <span>{{storeroomData.storageRoomNumber}}</span>
            </div>
            <div>
              <span class="title">完整编号：</span>
              <span>{{storeroomData.storageRoomFullNumber}}</span>
            </div>
          </div>
          <div class="carInfo">
            <div>
              <span class="title">办理时间:</span>
              <span>{{storeroomData.handleTime}}</span>
            </div>
            <div>
              <span class="title">到期时间:</span>
              <span>{{storeroomData.expireTime}}</span>
            </div>
          </div>
         <div class="carInfo">
            <div>
              <span class="title">库房状态:</span>
              <span>{{storeroomData.status}}</span>
            </div>
          </div>
          <div class="editCars" v-if="isShowUpdate" @click="editCars(storeroomData.id)">编辑该库房</div>
        </li>
         <div class="createCars" v-if="isShowAddStore" @click="createCars">登记库房信息</div>
      </ul>
    </form-panel>
  </div>
</template>
<script>
export default {
  name: 'StoreroomInfoForm',
  components: {},
  props: ['storeroom', 'userId', 'houseId'],
  data () {
    return {
      isShowUpdate: false,
      isShowAddStore: false,
      parkingLotData: []
    }
  },
  created () {
    this.controlPermission()
  },
  methods: {
    controlPermission () {
      const update = Number(this.$store.state.permission.update)
      const addStore = Number(this.$store.state.permission.addStore)
      this.isShowUpdate = update === 1
      this.isShowAddStore = addStore === 1
    },
    editCars (id) {
      this.$router.push({
        name: 'storeRoomForm',
        query: {
          id: id
        }
      })
    },
    createCars () {
      this.$router.push({
        name: 'storeRoomForm',
        query: {
          userId: this.userId.id,
          houseId: this.houseId.id
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.storeroomInfoForm-container {
  height: 100%;
}
.storeroomInfoForm{
  width:100%;
  .ownerHouseId{
      margin-left: 50px;
      .title{
        font-size: 14px;
        color: #606266;
        margin-right: 10px;
      }
    }
  .carInfo{
    div{
      display: inline-block;
      margin: 15px 0px 15px 0px;
      word-wrap: break-word;
      .required{
        color: #F56C6C;
      }
      .title{
        font-size: 14px;
        color: #606266;
        margin-right: 5px;
      }
    }
    div:nth-child(2n-1){
      width: 50%;
      margin-left: 48px;
    }
  }
  .editCars{
    border: 1px solid #1B8CF2;
    border-radius: 4px;
    background: #1B8CF2;
    color: white;
    text-align: center;
    line-height: 32px;
    width: 150px;
    height: 32px;
    margin: 5px 0 18px 64px;
    cursor: pointer;
  }
  .createCars{
    border: 1px solid #1B8CF2;
    border-radius: 4px;
    background: #1B8CF2;
    color: white;
    text-align: center;
    line-height: 32px;
    width: 150px;
    height: 32px;
    margin: 60px 0 15px 50px;
    cursor: pointer;
  }
}
</style>
