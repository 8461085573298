var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "baseInfoForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              hasHeader: false,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "el-form-item",
            { attrs: { label: "发票抬头", prop: "invoiceHeader" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.invoiceHeader,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "invoiceHeader", $$v)
                  },
                  expression: "form.invoiceHeader",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "企业开户行", prop: "enterpriseOpeningBank" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.enterpriseOpeningBank,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "enterpriseOpeningBank", $$v)
                  },
                  expression: "form.enterpriseOpeningBank",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "企业开户行账号",
                prop: "enterpriseOpeningBankAccount",
              },
            },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.enterpriseOpeningBankAccount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "enterpriseOpeningBankAccount", $$v)
                  },
                  expression: "form.enterpriseOpeningBankAccount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开户银行", prop: "openingBank" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.openingBank,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "openingBank", $$v)
                  },
                  expression: "form.openingBank",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "是否为单一大业主",
                rules: [
                  {
                    required: true,
                    message: "请选择是否为单一大业主",
                    trigger: "change",
                  },
                ],
                prop: "singleCompanyOwner",
              },
            },
            [
              _c("checkbox-plus", {
                attrs: {
                  value: _vm.form.singleCompanyOwner,
                  type: "radio",
                  options: _vm.isYesOptions,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.form, "singleCompanyOwner", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "邮寄地址",
                rules: [
                  {
                    required: true,
                    message: "请输入邮寄地址",
                    trigger: "blur",
                  },
                ],
                prop: "mailingAddress",
              },
            },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.mailingAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mailingAddress", $$v)
                  },
                  expression: "form.mailingAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "业主工作单位",
                rules: [
                  {
                    required: true,
                    message: "请输入业主工作单位",
                    trigger: "blur",
                  },
                ],
                prop: "ownerWorkCompany",
              },
            },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.ownerWorkCompany,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ownerWorkCompany", $$v)
                  },
                  expression: "form.ownerWorkCompany",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "单位属性", prop: "companyType" } },
            [
              _c("v-input", {
                attrs: { maxlength: 20 },
                model: {
                  value: _vm.form.companyType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "companyType", $$v)
                  },
                  expression: "form.companyType",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "单位规模", prop: "companyPeopleNum" } },
            [
              _c("v-input-number", {
                attrs: { max: 99999999 },
                model: {
                  value: _vm.form.companyPeopleNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "companyPeopleNum", $$v)
                  },
                  expression: "form.companyPeopleNum",
                },
              }),
              _vm._v("  人\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "单位地址", prop: "companyAddress" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.companyAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "companyAddress", $$v)
                  },
                  expression: "form.companyAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "行业", prop: "industry" } },
            [
              _c("v-input", {
                attrs: { maxlength: 20 },
                model: {
                  value: _vm.form.industry,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "industry", $$v)
                  },
                  expression: "form.industry",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }